.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #eceaf0;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  min-height: calc(100vh - 66px);
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: #223462;
}

.Admin-app-header {
  background-color: #eceaf0;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  min-height: calc(100vh - 74px);
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: #223462;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
